<template>
<div v-if="$parent.user != null" class="user" style="padding-top: 50px; display: flex; justify-content: center;" >
    <vs-row style="width: 69%;background: white;padding: 50px; gap: 20px; border-radius: 21px;" justify="space-between" >

        <vs-row align="center" justify="center">
            <img src="../assets/payment_review.png" style="width: 350px; height: auto;" />
        </vs-row>
        <vs-row align="center" justify="center">

            <h1 v-if="invoice.stripeInvoiceUrl != null">Il tuo pagamento è in fase di elaborazione.</h1>
            <h1 v-else>Il tuo ordine è stato registrato.</h1>

        </vs-row>
        <vs-row align="center" justify="center">
            <h3 v-if="invoice.stripeInvoiceUrl != null">Stiamo elaborando il tuo pagamento, riceverai a breve una email di conferma.<br />Qualora il pagamento non fosse andato a buon fine puoi riprovare tramite il bottone sottostante.</h3>
            <h3 v-else>A causa della quantità selezionata è necessario procedere con un pagamento manuale.<br/> Verrai contattato nelle prossime ore dal nostro team per un preventivo personalizzato.</h3>
        </vs-row>
        <vs-row align="center" justify="center" style="gap: 15px;">
            <!--<vs-button v-if="invoice.stripeInvoiceUrl != null" size="large" transparent icon>
                <i class='bx bxs-credit-card bx-sm'></i> Ritenta pagamento
            </vs-button>-->
            <vs-button size="large"  icon @click="$router.push('/user/orders')">
                <i class='bx bx-cart-alt bx-sm'></i> Vai ai miei ordini
            </vs-button>
        </vs-row>
    </vs-row>
</div>
</template>

<script>
export default {
    props: {
        invoice: Object
    },
    components: {

    },
    data() {
        return {

        }
    },
    mounted() {
        if (this.invoice == null) {
            this.$router.push({
                name: 'feed'
            }); // tentativo di accesso diretto
        }
    },

    watch: {

    },

    methods: {

    }
}
</script>
